<template>
  <div>
    <button @click="startScan">扫码</button>
    <p v-if="qrCodeContent" style="display: inline-block; margin-left: 10px;">{{ qrCodeContent }}</p>
    <div id="reader" style="width: 100%;"></div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue';
import { Html5Qrcode } from 'html5-qrcode';

const qrCodeContent = ref('');
let html5QrcodeScanner = null;

function startScan() {
  if (!html5QrcodeScanner) {
    html5QrcodeScanner = new Html5Qrcode("reader");
  }
  
  html5QrcodeScanner.start(
    { facingMode: "environment" }, 
    { fps: 10, qrbox: 250 },
    (decodedText) => {
      qrCodeContent.value = decodedText;
      stopScan();
    },
    (errorMessage) => {
      // optionally log any errors or handle them
      console.log("QR decoding error:", errorMessage);
    }
  ).catch(err => {
    console.error("Unable to start scanning, error:", err);
  });
}

function stopScan() {
  if (html5QrcodeScanner) {
    html5QrcodeScanner.stop().then(() => {
      console.log("QR Code scanning stopped.");
    }).catch(err => {
      console.error("Error stopping QR scanner.", err);
    });
  }
}

onBeforeUnmount(() => {
  stopScan();
});
</script>

<style scoped>
#reader {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: auto; /* Ensure it adjusts to the content */
}
</style>
